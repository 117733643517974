import "./App.css";
import React, { Suspense, lazy, useState } from "react";
import { ConfigProvider } from "antd";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { store } from "./store";

import { Provider } from "react-redux";
import setAuthToken from "./utils/setAuthToken";
import AdminRoute from "./routes/AdminRoute";
import Sidebar from "./layout/Sidebar";
import Navbar from "./layout/Navbar";
import { saveConfig, setAdmin } from "./actions/AuthAction";
import { setConfig } from "./reducers/configReducer";

import { isPermitted } from "./utils/PermissionManager";
import { setProfile } from "./reducers/authReducer";
import Bottombar from "./layout/BottomBar";
import { siteInfo } from "./utils/SiteInfo";

const BulkDiscount = lazy(() => import("./pages/BulkDiscount"));
const Invoice = lazy(() => import("./pages/Invoice"));

const BlogCategories = lazy(() => import("./pages/BlogCategories"));
const BlogPosts = lazy(() => import("./pages/BlogPosts"));
const ManageBlog = lazy(() => import("./pages/ManageBlog"));

const Payments = lazy(() => import("./pages/Payments"));
const MonthlyReport = lazy(() => import("./pages/MonthlyReport"));
const CustomerReport = lazy(() => import("./pages/CustomerReport"));
const ProductReport = lazy(() => import("./pages/ProductReport"));

const HomeBlocks = lazy(() => import("./pages/HomeBlocks"));
const Categories = lazy(() => import("./pages/Categories"));
const ManageProduct = lazy(() => import("./pages/ManageProduct"));
const Products = lazy(() => import("./pages/Products"));
const Brands = lazy(() => import("./pages/Brands"));
const SubCategories = lazy(() => import("./pages/SubCategories"));
const Coupon = lazy(() => import("./pages/Coupon"));
const Banners = lazy(() => import("./pages/Banners"));
const MetaSettings = lazy(() => import("./pages/MetaSettings"));
const Pages = lazy(() => import("./pages/Pages"));
const ManageComboProduct = lazy(() => import("./pages/ManageComboProduct"));
const Orders = lazy(() => import("./pages/Orders"));
const ManageOrder = lazy(() => import("./pages/ManageOrder"));

const Customers = lazy(() => import("./pages/Customers"));
const Login = lazy(() => import("./pages/Login"));
const Admins = lazy(() => import("./pages/Admins"));
const Profile = lazy(() => import("./pages/Profile"));
const ComingSoon = lazy(() => import("./pages/ComingSoon"));

if (localStorage.config) {
  let config = JSON.parse(localStorage.config);
  let profile = config.profile;
  store.dispatch(setProfile(profile));
  store.dispatch(setConfig(config));
}

if (localStorage[siteInfo.sitekey]) {
  setAuthToken(localStorage[siteInfo.sitekey]);
  store.dispatch(setAdmin(true));
  store.dispatch(saveConfig());
}

const Dashboard = lazy(() => import("./pages/Dashboard"));

function App() {
  const client = window.screen.width > 768 ? "web" : "app";
  const [show, setshow] = useState(client === "web" ? false : true);
  const [trigger, settrigger] = useState(false);

  const id = store.getState().auth?.profile?._id;

  const token = localStorage[siteInfo.sitekey];

  const roles = store.getState().auth?.profile?.roles
    ? store.getState().auth?.profile?.roles
    : [];

  return (
    <Provider store={store}>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#161A30",
          },
        }}
      >
        {token ? (
          <BrowserRouter>
            <Suspense fallback={<div className="pageProgress"></div>}>
              <div className="App">
                <Sidebar state={{ show, setshow }} />
                <div>
                  <Navbar state={{ settrigger, setshow, show }} />
                  {id && <Bottombar />}

                  <Routes>
                    <Route
                      exact
                      path="/"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<Dashboard />}
                        />
                      }
                    />

                    <Route
                      exact
                      path="/profile"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<Profile />}
                        />
                      }
                    />

                    <Route
                      exact
                      path="/customers"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<Customers />}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/pages"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<Pages />}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/banner/:id?"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<Banners />}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/categories"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<Categories />}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/blog/posts"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<BlogPosts />}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/blog/categories"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<BlogCategories />}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/coupon"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<Coupon />}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/bulk-discount"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<BulkDiscount />}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/home-block"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<HomeBlocks />}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/settings/:id?"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<MetaSettings />}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/category/:id"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<SubCategories />}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/brands"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<Brands />}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/product/:id?"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<ManageProduct />}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/blog/manage-post/:id?"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<ManageBlog />}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/product/combo/:id?"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<ManageComboProduct />}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/products"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<Products />}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/orders"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<Orders />}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/order/:code"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<ManageOrder />}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/invoice/:code"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<Invoice />}
                        />
                      }
                    />

                    <Route
                      exact
                      path="/payments"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<Payments />}
                        />
                      }
                    />

                    <Route
                      exact
                      path="/order-report"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<MonthlyReport />}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/customer-report"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<CustomerReport />}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/product-report"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<ProductReport />}
                        />
                      }
                    />

                    {/* <Route
                      exact
                      path="/orders"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<Bookings />}
                        />
                      }
                    />
                    {isPermitted(roles, ["admin", "reservation_officer"]) && (
                      <Route
                        exact
                        path="/manage-booking/:id?"
                        element={
                          <AdminRoute
                            state={{ show, trigger }}
                            component={<CreateBooking />}
                          />
                        }
                      />
                    )}
                    <Route
                      exact
                      path="/booking-details/:id"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<BookingDetails />}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/vendors"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<Vendors />}
                        />
                      }
                    />
                    {isPermitted(roles, ["admin", "accountant"]) && (
                      <Route
                        exact
                        path="/vendor-counter"
                        element={
                          <AdminRoute
                            state={{ show, trigger }}
                            component={<VendorCounter />}
                          />
                        }
                      />
                    )}
                    {isPermitted(roles, ["admin", "accountant"]) && (
                      <Route
                        exact
                        path="/customer-counter"
                        element={
                          <AdminRoute
                            state={{ show, trigger }}
                            component={<CustomerCounter />}
                          />
                        }
                      />
                    )}
                    {isPermitted(roles, ["admin", "accountant"]) && (
                      <Route
                        exact
                        path="/banks"
                        element={
                          <AdminRoute
                            state={{ show, trigger }}
                            component={<Banks />}
                          />
                        }
                      />
                    )}
                    {isPermitted(roles, ["admin", "accountant"]) && (
                      <Route
                        exact
                        path="/payments"
                        element={
                          <AdminRoute
                            state={{ show, trigger }}
                            component={<Payments />}
                          />
                        }
                      />
                    )}

                    {isPermitted(roles, ["admin"]) && (
                      <Route
                        exact
                        path="/report/booking"
                        element={
                          <AdminRoute
                            state={{ show, trigger }}
                            component={<MonthlyReport />}
                          />
                        }
                      />
                    )}
                    {isPermitted(roles, ["admin"]) && (
                      <Route
                        exact
                        path="/report/segment"
                        element={
                          <AdminRoute
                            state={{ show, trigger }}
                            component={<SegmentReport />}
                          />
                        }
                      />
                    )}
                    {isPermitted(roles, ["admin"]) && (
                      <Route
                        exact
                        path="/report/payment"
                        element={
                          <AdminRoute
                            state={{ show, trigger }}
                            component={<PaymentReport />}
                          />
                        }
                      />
                    )}

                    <Route
                      exact
                      path="/vendor-bank/:id"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<VendorBank />}
                        />
                      }
                    />
                    {isPermitted(roles, ["admin", "accountant"]) && (
                      <Route
                        exact
                        path="/payment/:type/:id"
                        element={
                          <AdminRoute
                            state={{ show, trigger }}
                            component={<Payment />}
                          />
                        }
                      />
                    )}

                    <Route
                      exact
                      path="/payment/confirmation/:key"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<Confirmation />}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/invoice/:key"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<Invoice />}
                        />
                      }
                    />

                    {isPermitted(roles, ["admin", "accountant"]) && (
                      <Route
                        exact
                        path="/payments"
                        element={
                          <AdminRoute
                            state={{ show, trigger }}
                            component={<Payments />}
                          />
                        }
                      />
                    )} */}

                    {isPermitted(roles, ["admin"]) && (
                      <Route
                        exact
                        path="/admins"
                        element={
                          <AdminRoute
                            state={{ show, trigger }}
                            component={<Admins />}
                          />
                        }
                      />
                    )}

                    <Route exact path="/login" element={<Login />} />
                    <Route
                      path="*"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={
                            <div
                              style={{
                                height: "calc(100vh - 78px)",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                backgroundColor: "#fff",
                              }}
                            >
                              <ComingSoon />
                            </div>
                          }
                        />
                      }
                    />
                  </Routes>
                </div>
              </div>
            </Suspense>
          </BrowserRouter>
        ) : (
          <BrowserRouter>
            <Routes>
              <Route path="*" element={<Login />} />
            </Routes>
          </BrowserRouter>
        )}
      </ConfigProvider>
    </Provider>
  );
}

export default App;
